import './Home.css';
import React from 'react';

function Home() {
    return (
        <>
            <video className='vid' autoPlay muted loop>
                <source src='.\background.mp4' type='video/mp4' />
                
            </video>
            
            <div className='box'> 

                <div className='content'>
                    <h3 className='text1'>O FUTURO É DOS GAMERS E A F11 ESTÁ PREPARANDO UMA SUPER NOVIDADE</h3>

                    <h1 className='text2'>JOGAR É LEGAL, MAS CRIAR SEUS PRÓPRIOS JOGOS É INCRÍVEL!</h1>

                    <h2 className='text1'>A PRIMEIRA GAME JAM PARA CRIANÇAS DO BRASIL</h2>

                    <a  
                    href="/euquero">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        EU QUERO!
                    </a>
                </div>
            </div>
        </>
    )


};
export default Home;