import './App.css';
import React from 'react';
import ApplicationRoutes from './pages/ApplicationRoutes';

function App() {
  return (
    <ApplicationRoutes/>
  );
}

export default App;

