import "./Inicial.css";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";

function Inicial() {
  return (
    <section className="gradient">
      <header>
        <img className="logo" src=".\logo.png" alt="F11" />
        <a className='button' href='https://7xi8kyrq3pn.typeform.com/to/nK64hnEN' target="_blank"
          rel="noreferrer noopener">Inscrever-se</a>
      </header>
      <main>
        <section className="section1">
          <ul className="titulos">
            <li>31 - 03</li>
            <li>Outubro</li>
            <li>Bits Jam</li>
            <li className="hora">20H</li>
          </ul>
          <img
            className="BitsSurpresa "
            src=".\notbook.png"
            alt="notbook gamer"
          />
        </section>
        <section className="section2">
          <ul className="subtitulos">
            <li>31 - 03 Outubro</li>
            <li>Bits Jam</li>
          </ul>
          <div className="icons">
            <a
              className="icon"
              href="https://web.facebook.com/people/F11-Programe-se/100085692630614/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebook className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.tiktok.com/@f11kids?lang=pt-BR"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.instagram.com/f11_kids/?fbclid=IwAR3DkJGMKZ73oD-l255XsshGMcDOZdIuOP8b65WKiML9giWcHDAV93sAih0"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.youtube.com/channel/UCeyYpXl6da0WDcs0llyBbrQ/featured"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaYoutube className="icon" />
            </a>
            <a
              className="icon"
              href="https://discord.gg/UGS8kq6P"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaDiscord className="icon" />
            </a>
          </div>
        </section>
        <section className="section3">
          <div className="lista1">
            <div className="tituloP">Bem-vindos a BitsJam !!!</div>
            <img className="BitsPc" src=".\bitsPc.png" alt="" />
          </div>
          <div className="lista2">
            <div className="text-list">
              Chegamos com tuuuudo para engajar nossas crianças e jovens a se
              desenvolverem no mundo da programação e da tecnologia com muita
              diversão. Afinal, se divertir faz parte do processo de aprender
              coisas novas e desenvolver habilidades geniais. E para provar
              isso, a F11 traz com exclusividade o primeiro GAME JAM INFANTIL DO
              BRASIL
            </div>
            <div className='tituloP'>
              VÃO SER 4 DIAS DE PREPARAÇÃO INDIVIDUAL E EM EQUIPE AO VIVO!
            </div>
            <div className='inscrever'>
              <a className='button inscrever' href='https://7xi8kyrq3pn.typeform.com/to/nK64hnEN' target="_blank"rel="noreferrer noopener">
                Inscrever-se
              </a>
            </div>
          </div>
        </section>
        <section className='section4'>
          <div className='section4-lista'>
            <div className='item4'>O QUE É UMA GAME JAM?</div>
            <div className='item4-1'>
              A Bits GameJam é uma maratona que oferece a crianças de 7 a 14 anos a chance de desenvolver um Game em apenas 4 dias!
            </div>
          </div>
        </section>
        <section className='section5'>
          <p className='tituloRegulamento'>Confira mais em nosso regulamento:</p>
          <a className='button regulamento' 
            href='https://docs.google.com/document/d/1ihIjsba7RClhxojR_Jt2o0r2iv6yNJtXbJuwbdNI5Yw/edit?usp=sharing'
            target="_blank"
            rel="noreferrer noopener">Regulamento</a>
        </section>
      </main>
      <footer>
        <section className="informacoes">
          © 2022 • Bits Game Jam • All Rights Reserved •
        </section>
        <section className="section2">
          <ul className="subtitulos">
            <li>Siga-nos</li>
            <li>em nossas redes sociais</li>
          </ul>
          <div className="icons">
            <a
              className="icon"
              href="https://web.facebook.com/people/F11-Programe-se/100085692630614/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebook className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.tiktok.com/@f11kids?lang=pt-BR"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.instagram.com/f11_kids/?fbclid=IwAR3DkJGMKZ73oD-l255XsshGMcDOZdIuOP8b65WKiML9giWcHDAV93sAih0"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              className="icon"
              href="https://www.youtube.com/channel/UCeyYpXl6da0WDcs0llyBbrQ/featured"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaYoutube className="icon" />
            </a>
            <a
              className="icon"
              href="https://discord.gg/UGS8kq6P"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaDiscord className="icon" />
            </a>
          </div>
        </section>
      </footer>
    </section>
  );
}

export default Inicial;
