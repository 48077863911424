import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom"

import Home from "./home/Home";
import Inicial from "./inicial/Inicial";

const ApplicationRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Home/>} path="/" exact />
                <Route element={<Inicial/>} path="/euquero"/>
            </Routes >
        </BrowserRouter>

    )
}

export default ApplicationRoutes;